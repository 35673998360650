import React from 'react';
import {Container} from 'react-bootstrap';

import Layout from '../components/layout';

const TermsOfUse = () => (
  <Layout>
    <Container className="my-5">
      <h1 className="mb-4">Terms of Use (Non-Negotiable)</h1>
      <div>
        <section>
          <div>
            <p>
              <i>V.3 Effective: May 1, 2024</i>
            </p>
            <p>
              <b>
                IMPORTANT NOTICE: THESE TERMS CONTAIN A BINDING ARBITRATION PROVISION AND CLASS
                ACTION WAIVER. IT AFFECTS YOUR LEGAL RIGHTS AS DETAILED IN THE ARBITRATION AND CLASS
                ACTION WAIVER SECTION BELOW. PLEASE READ CAREFULLY.
              </b>
            </p>
            <p>
              This Terms of Use (&apos;Terms&apos;), including the BINDING ARBITRATION AND CLASS
              ACTION WAIVER BELOW, governs your access to and use of St. Jude Cloud, for genomic
              information and its analysis, other tools and software, as well as related programs
              and data, made available and owned by St. Jude Children&apos;s Research Hospital, Inc.
              (&apos;St. Jude,&apos; &apos;we,&apos; &apos;our,&apos; or &apos;us&apos;). By
              accessing or using any portion of St. Jude Cloud including apps available through St.
              Jude Cloud (&apos;Apps&apos;) and St. Jude programs (&apos;St. Jude Programs&apos;) or
              any data available there (&apos;St. Jude Data&apos;) (collectively, referred to below
              as &apos;St. Jude Cloud&apos;), you hereby agree to these Terms and agree to comply
              with all applicable laws. Please read the Terms carefully before accessing St. Jude
              Cloud. If you do not agree to be bound by these Terms, you are not permitted to
              access, enter, or use any part of St. Jude Cloud.
            </p>
            <p>
              You should also review the St. Jude Cloud Privacy Policy (
              <a href="/privacy-policy">https://stjude.cloud/privacy-policy</a>), which is
              incorporated herein by reference and governs St. Jude&apos;s collection and use of
              information.
            </p>
            <h2>Eligibility to Use St. Jude Cloud</h2>
            <p>
              All references to &apos;User,&apos; &apos;you,&apos; or &apos;your,&apos; as
              applicable, mean the person who accesses or uses St. Jude Cloud in any manner, and
              each of your heirs, assigns, and successors.
            </p>
            <p>
              By accessing or using St. Jude Cloud, you represent and warrant that you are at least
              18 years old and able to form legally binding contracts under applicable law.
            </p>
            <p>
              If you access or use St. Jude Cloud, you bind yourself and, solely to the extent
              allowed, your Institution, to these Terms, and your acceptance of the Terms will be
              deemed an acceptance by that entity, and &apos;you&apos; and &apos;your&apos; herein
              shall refer to that entity.
            </p>
            <h2>Limited License to Use St. Jude Cloud</h2>
            <p>
              Subject to your agreement and compliance with these Terms (including the Privacy
              Policy), St. Jude grants you a limited, revocable, non-exclusive, royalty-free,
              non-transferable, non-sublicenseable license for a limited period of time to access
              St. Jude Cloud, to process, analyze, access, or display data.{' '}
              <b>
                No license is given to you if you are located in a country subject to comprehensive
                economic sanctions by the United States Department of Treasury. No license is given
                if you are listed as a restricted party by the United States Departments of
                Commerce, Treasury or State.
              </b>{' '}
              No licenses or rights are granted to you by implication or otherwise under any
              intellectual property rights owned or controlled by St. Jude or its licensors, except
              for the licenses and rights expressly granted in these Terms.
            </p>
            <h2>Modifications to the Terms &amp; St. Jude Cloud</h2>
            <p>
              St. Jude, in its sole discretion, reserves the right at any time to, among other
              actions, and without advance notice:
            </p>
            <ul>
              <li>
                Change these Terms, except for the section titled &quot;Arbitration and Class
                Waiver&quot; which may be changed in accordance with the provisions in that section;
              </li>
              <li>
                Change St. Jude Cloud, including eliminating or discontinuing St. Jude Cloud, the
                St. Jude Apps, the St. Jude Programs, the St. Jude Data, any content on or feature
                of St. Jude Cloud;
              </li>
              <li>Deny or terminate your use of and/or access to St. Jude Cloud; and</li>
              <li>
                Refuse or remove any material or data, including but not limited to genomic
                sequences that you might place in, upload to, or use with St. Jude Cloud, and any
                visualizations you generate through St. Jude Cloud or any part of it.
              </li>
            </ul>
            <p>
              Unless otherwise stated, any changes we make to the Terms or St. Jude Cloud will be
              effective immediately when posted on St. Jude Cloud. You agree that accessing,
              entering, using, or continuing to use St. Jude Cloud after any such changes
              constitutes your consent to all such changes. If you do not consent, you may not
              access or use St. Jude Cloud. Be sure to return to this page periodically to ensure
              your familiarity with the most current version of the Terms. St. Jude does not
              negotiate these Terms.
            </p>
            <h3>Ownership Acknowledgement</h3>
            <p>
              St. Jude Cloud includes data (referred to as &apos;St. Jude Data&apos;) and St. Jude
              Apps and St. Jude Programs, including any software, analysis tools, or visualization
              tools used in any publication, presentation, patent application, or otherwise, owned
              by St. Jude (referred to as &apos;St. Jude Programs&apos;). User shall acknowledge St.
              Jude as the provider of St. Jude Data, St. Jude Programs, the St. Jude Apps, and/or
              any software or analysis tool in any publication, presentation, or patent application
              using St. Jude Data and/or St. Jude Programs or St. Jude Apps. User shall notify St.
              Jude of any such publication or presentation within thirty (30) calendar days of
              public disclosure and of any such patent application within thirty (30) calendar days
              of its filing. To provide this notification, please complete the form at this link:{' '}
              <a
                href="https://hospital.stjude.org/apps/forms/fb/st-jude-cloud-contact/"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://stjude.cloud/contact
              </a>
              .
            </p>
            <h3>Intellectual Property &amp; Feedback</h3>
            <p>
              St. Jude Cloud, St. Jude Data, St. Jude Apps, and St. Jude Programs are protected by
              patent, copyright, trademark, trade secret and other laws of the United States,
              foreign countries, and international conventions. Except as expressly provided in
              these Terms, St. Jude and its licensors exclusively own all right, title, and interest
              in and to St. Jude Cloud and all technology underlying the same, including all
              associated intellectual property rights. All trademarks, service marks, logos, trade
              names and any other proprietary designations of St. Jude used herein are trademarks or
              registered trademarks of St. Jude. Any other trademarks, service marks, logos, trade
              names and any other proprietary designations are the trademarks or registered
              trademarks of their respective owners.
            </p>
            <p>
              By sending us any feedback, comments, questions, or suggestions concerning St. Jude
              Cloud or any part of it (collectively, &apos;Feedback&apos;), you represent and
              warrant that (a) you have the right to disclose the Feedback, (b) the Feedback does
              not violate the rights of any other person or entity, and (c) the Feedback does not
              contain the confidential or proprietary information of any third party or parties. By
              sending us any Feedback, you (i) agree that St. Jude is under no obligation of
              confidentiality, express or implied, with respect to the Feedback, (ii) acknowledge
              that we may have something similar to the Feedback already under consideration or in
              development, (iii) grant us an irrevocable, non-exclusive, royalty-free, perpetual,
              worldwide license to use, modify, prepare derivative works, publish, distribute and
              sublicense the Feedback, and (iv) irrevocably waive, and cause to be waived, against
              St. Jude and its Users any claims and assertions of any moral rights contained in such
              Feedback. This Feedback section shall survive any termination of your account or St.
              Jude Cloud.
            </p>
            <h3>Use Restrictions</h3>
            <p>
              The St. Jude Programs and St. Jude Apps may be used only on St. Jude Cloud (unless
              otherwise accessible from the DNAnexus platform), and User agrees to use them only on
              St. Jude Cloud or, as applicable, the DNAnexus platform, unless a separate agreement
              is entered into by User, User&apos;s organization, and an authorized representative of
              St. Jude.
            </p>
            <p>
              User agrees not to download, reverse engineer, transfer, translate, modify, adapt,
              enhance, decompile, disassemble, or try to determine source code from the St. Jude
              Data, St. Jude Apps, St. Jude Programs, or St. Jude Cloud. User agrees not to share
              any St. Jude Programs or St. Jude Apps with any third party or with any person or
              entity without written permission from an authorized representative of St. Jude. User
              will not share User&apos;s access to St. Jude Cloud with anyone else. User will not
              share St. Jude Programs or St. Jude Apps or assist in providing access to them or to
              St. Jude Cloud with or to anyone located or ordinarily resident in a country subject
              to comprehensive economic sanctions by the United States Department of Treasury or
              with anyone listed as a restricted party by the United States Departments of Commerce,
              Treasury or State.
            </p>
            <p>
              Except as permitted by an applicable, executed Data Access Agreement, User will not
              obtain, retain, or share the St. Jude Data or the URL by viewing the source code or
              monitoring the activity of any of any St. Jude App or St. Jude Program.
            </p>
            <p>
              User shall promptly inform St. Jude at{' '}
              <a
                href="https://hospital.stjude.org/apps/forms/fb/st-jude-cloud-contact/"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://stjude.cloud/contact
              </a>{' '}
              if User becomes aware of any unauthorized download or transfer of the St. Jude Data,
              St. Jude Apps, or St. Jude Programs from St. Jude Cloud. User will use all
              commercially reasonable efforts to prevent unauthorized access to St. Jude Cloud. St.
              Jude may take action against User for violations of this provision, including but not
              limited to St. Jude blocking User from using St. Jude Cloud and employing all
              available legal remedies.
            </p>
            <p>
              User agrees that St. Jude Cloud, St. Jude Data, St. Jude Apps, and St. Jude Programs
              will be used for educational and basic research purposes only and not for any other
              purpose. User shall not use St. Jude Cloud, St. Jude Data, St. Jude Apps, or St. Jude
              Programs for diagnosis, medical care, or treatment of any patient, or for
              decision-making about any person (including allocation to a study or specific study
              arm or group), or to render medical advice or professional services, or for an
              commercial purpose, including use in research sponsored by a commercial entity (unless
              by separate, signed agreement). User acknowledges and understands that St. Jude Cloud,
              St. Jude Data, St. Jude Apps, and St. Jude Programs have not received approval or
              clearance by the U.S. Food and Drug Agency or otherwise. User agrees that St. Jude
              Cloud, St. Jude Programs, St. Jude Apps, and St. Jude Data are intended only to assist
              in research but not in decision-making about patients or research participants, and
              that User is and remains solely responsible for all decisions made regardless of
              whether they are so used. You will not use any data from St. Jude Cloud in any manner
              that violates the applicable law(s) in the United States or the User&apos;s resident
              country or location. User warrants that User will obtain all required approvals for
              the use of the the St. Jude Data, St. Jude Apps, or St. Jude Programs.
            </p>
            <p>
              User shall not attempt to learn or discern the identity of any individual from any
              Data available via St. Jude Cloud. In the unlikely event that User suspects the
              identity of any individual in the St. Jude Data, User shall not disclose such
              information.
            </p>
            <p>
              User shall not upload any data or use any data in conjunction with St. Jude Cloud, St.
              Jude Data, St. Jude Apps, and/or St. Jude Programs that is inappropriate or unlawful
              or contains any virus, malware, spyware, worms, Trojan horses, time bombs, cancelbots,
              hostile or intrusive program codes that are designed to perform malicious operations
              on a computer or in any other way poses a cyber or informational threat to St. Jude
              Cloud, St. Jude Data, St. Jude Apps, and/or St. Jude Programs, or St. Jude.
            </p>
            <p>
              User will take no action that will adversely affect the speed, security, or integrity
              of St. Jude Cloud, St. Jude Data, St. Jude Apps, and/or St. Jude Programs.
            </p>
            <p>
              User shall not share any encryption keys, access tokens, or other access to St. Jude
              Apps or St. Jude Programs with any other person, except as permitted herein or in any
              subsequent written agreement of the parties, or in any by an authorized representative
              of St. Jude. User shall not take any action that imposes an unreasonable burden on the
              infrastructure used to support the efficient operations of St. Jude Cloud or St. Jude
              Programs or St. Jude Apps.
            </p>
            <p>
              User acknowledges the applicability of the DNAnexus and Azure terms and privacy
              policies, including{' '}
              <a href="https://www.dnanexus.com/terms" rel="noopener noreferrer" target="_blank">
                https://www.dnanexus.com/terms
              </a>
              ,{' '}
              <a href="https://www.dnanexus.com/privacy" rel="noopener noreferrer" target="_blank">
                https://www.dnanexus.com/privacy
              </a>
              , and{' '}
              <a
                href="https://azure.microsoft.com/en-us/support/legal/"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://azure.microsoft.com/en-us/support/legal/
              </a>
              .
            </p>
            <h3>User Data and Submissions</h3>
            <p>
              St. Jude Cloud may permit Users to upload certain information or data (&apos;User
              Data&apos;). User acknowledges that User is and shall be responsible for all User Data
              uploaded onto or used in or with St. Jude Cloud, St. Jude Apps, and St. Jude Programs.
            </p>
            <p>
              By uploading any User Data to St. Jude Cloud, you hereby grant to St. Jude, its
              representatives, and third parties DNAnexus, Microsoft, and other third parties
              involved in providing St. Jude Cloud, a worldwide, non-exclusive, royalty-free license
              to use, copy, reproduce, process, adapt, modify, create derivative works from,
              publish, transmit, share, store, display and distribute, translate, communicate and
              otherwise maintain and use User Data solely in connection with the operation or use of
              the St. Jude Cloud. You may upload omics data, variant data, metadata, other
              information related to such omics data, or other information or files held within the
              user-specific project on St. Jude Cloud or on DNAnexus (collectively, &apos;User
              &apos;User Research Data&apos;). St. Jude does not intend to access User Research Data
              unless you explicitly grant access the St Jude, provided, however, regardless of your
              account and sharing settings, St. Jude will have access to data visualizations created
              through tools made available in the St. Jude Apps, including but not limited those
              made in St. Jude Cloud Visualization Community using ProteinPaint and GenomePaint.
              Also, in rare circumstances, St Jude may work with DNAnexus to gain access and/or
              remove User Research Data. St. Jude reserves the right to access or remove User
              Research Data or User Data as necessary to provide St. Jude Cloud services as offered,
              for security and fraud-prevention purposes, protection of St. Jude and its agents, and
              as required by law.
            </p>
            <p>
              The license granted to St. Jude herein shall survive termination of St. Jude Cloud.
              Nothing in these Terms will be deemed to restrict rights that you may have to use and
              exploit User Data. User may remove User&apos;s own User Data from St. Jude Cloud. You
              acknowledge and agree that you are solely responsible for all User Data that you
              upload. You represent and warrant that: (a) you either are the sole and exclusive
              owner of all User Data that you upload or you have all rights, licenses, consents,
              approvals, authorizations, and releases that are necessary under applicable law to
              grant to St. Jude the rights in such User Data, as contemplated under these Terms and,
              if you choose to share User Data or visualizations within St. Jude Cloud, to make
              available such User Data or visualizations derived from them to other Users; and (b)
              neither the User Data, creation of visualizations, nor your uploading, submission or
              transmittal of the User Data or St. Jude&apos;s use of your User Data (or any portion
              thereof) on, through or by means of St. Jude Cloud will infringe, misappropriate or
              violate a third party&apos;s patent, copyright, trademark, trade secret, moral rights
              or other proprietary or intellectual property rights, or rights of publicity or
              privacy of any individual or entity, or result in the violation of any applicable law
              or regulation.
            </p>
            <p>
              St. Jude reserves the right, at any time and without prior notice, to remove or
              disable access to User Data or visualization data that we, in our sole discretion,
              consider to be objectionable for any reason, in violation of these Terms or otherwise
              harmful to St. Jude Cloud, Users, or for any other reason.
            </p>
            <p>
              YOU AGREE THAT YOU WILL UPLOAD DATA ONLY AS PERMITTED BY THESE TERMS AND ST. JUDE
              CLOUD (SUCH AS GENOMIC AND DE-IDENTIFIED DATA) AND YOU MAY NOT AND WILL NOT UPLOAD ANY
              PROTECTED HEALTH INFORMATION OR ANY OTHER PERSONALLY IDENTIFYING INFORMATION, PERSONAL
              INFORMATION, OR PERSONAL DATA, AS DEFINED BY APPLICABLE LAW(S) IN THE UNITED STATES OR
              THE USER&apos;S RESIDENT COUNTRY OR LOCATION, OR THE LOCATION FROM WHICH THE DATA
              ORIGINATED, TO ST. JUDE CLOUD, THE ST. JUDE PROGRAMS, THE ST. JUDE APPS, OR THE ST.
              JUDE DATA. YOU MAY NOT AND WILL NOT UPLOAD ANY INFORMATION OR DATA, INCLUDING GENETIC
              OR GENOMIC DATA, WITHOUT APPROPRIATE CONSENT AND AUTHORIZATION OR TO THE EXTENT
              OTHERWISE PERMITTED BY APPLICABLE LAW, AND YOU WILL UPLOAD SUCH DATA ONLY IN
              COMPLIANCE WITH ALL APPLICABLE LAW. YOU REPRESENT AND WARRANT THAT YOUR UPLOADING USER
              DATA WILL NOT VIOLATE ANY AGREEMENT, GRANT, CONTRACT, OR ARRANGEMENT. YOU WILL NOT
              UPLOAD ANY DATA WITH PROTECTED HEALTH INFORMATION (PHI) OR PERSONALLY IDENTIFYING
              INFORMATION AS DEFINED BY US LAW, OR ANY DATA CONTAINING WITH THE NAME OR IDENTIFYING
              INFORMATION ABOUT THE INDIVIDUAL TO WHOM THE DATA PERTAINS.
            </p>
            <p>
              User warrants that User Data contains no defamatory, malicious, or false content. You
              may not upload any information that is illegal, threatening, libelous, defamatory,
              obscene, ethnically or racially offensive or discriminatory, profane, or that
              infringes the copyright or other intellectual property right of any third party,
              invades the privacy of another person, constitutes a crime, gives rise to a civil
              action, or otherwise violates any law regarding use of information or data, human
              subject research, or privacy or confidentiality, or any law or obligation of
              confidentiality or secrecy.
            </p>
            <h3>Legal Compliance; Liability; Indemnity; Arbitration</h3>
            <p>
              User shall comply with all applicable laws, rules, regulations, and guidance when
              using St. Jude Cloud and uploading data to St. Jude Cloud. This includes, but is not
              limited to, human subject protection laws and privacy laws of the U.S. and the country
              of the User, as well as any specific restrictions associated with any uploaded data or
              usage of any data or information resulting from User&apos;s of St. Jude Cloud, the St.
              Jude Data, the St. Jude Apps, and/or St. Jude Programs.
            </p>
            <p>
              User is responsible for obtaining permission for all use of any information or data,
              including genomic sequences, uploaded or used on St. Jude Cloud, the St. Jude Apps, or
              the St. Jude Programs, or with the St. Jude Data, and User assumes all liability for
              doing so.
            </p>
            <p>
              St. Jude Cloud, St. Jude Data, St. Jude Programs, and St. Jude Apps are subject to
              U.S. and local export and import control laws and regulations. User agrees that User
              will comply with all applicable U.S. and local export and import control laws and
              regulations, including those concerning &apos;anti-boycott,&apos; &apos;deemed
              export,&apos; and &apos;deemed re-export,&apos; in connection with User&apos;s use of
              St. Jude Cloud, St. Jude Programs, and St. Jude Apps. It is the sole responsibility of
              User to ensure it has the right to use all features of St. Jude Cloud, St. Jude
              Programs, and St. Jude Apps in User&apos;s jurisdiction, and User agrees not to use
              St. Jude Cloud, St. Jude Programs, and/or St. Jude Apps if those laws conflict with
              U.S. law. User shall comply with all laws and regulations governing use, export,
              re-export, and transfer of St. Jude Cloud, St. Jude Data, St. Jude Programs, and St.
              Jude Apps and will obtain all required U.S. and local authorizations, permits, or
              licenses. User certifies that User will not use St. Jude Cloud, St. Jude Programs, or
              St. Jude Apps from within a country subject to comprehensive economic sanctions by the
              U.S Department of Treasury, and that User will not use St. Jude Cloud, St. Jude
              Programs, or St. Jude Apps if User is located or ordinarily resident in, or under the
              control of, any comprehensively sanctioned country. User certifies that neither User
              nor User&apos;s Institution is on the U.S. Department of Commerce&apos;s Denied
              Persons List or affiliated lists, on the U.S. Department of Treasury&apos;s Specially
              Designated Nationals List, or on any U.S. Government export exclusion lists. The
              export obligations under this clause shall survive the expiration or termination of
              the Agreement. St. Jude may modify or not make available the St. Jude Programs and St.
              Jude Apps and/or certain St. Jude Programs or St. Jude Apps features, or St. Jude
              Cloud to comply with applicable laws and regulations.
            </p>
            <h4>Copyright Policy &amp; Complaints</h4>
            <p>
              We expect Users to respect copyright law. In appropriate circumstances, we will
              terminate access to St. Jude Cloud of any User who repeatedly infringes or is believed
              to be repeatedly infringing the rights of copyright holders. We will remove or disable
              access to material on St. Jude Cloud that is found to infringe upon the copyright
              rights of others. We also may, in our discretion, remove or disable links or
              references to an online location that contains infringing material or infringing
              activity.
            </p>
            <p>
              Digital Millennium Copyright Act. If you believe that your work has been used on St.
              Jude Cloud in any manner that constitutes copyright infringement, please notify St.
              Jude&apos;s copyright agent by written notice.
            </p>
            <p>
              <i>Notice</i>. The notice should include the following information:
            </p>
            <ul>
              <li>
                An electronic or physical signature of a person authorized to act on behalf of the
                owner of the copyright allegedly infringed;
              </li>
              <li>
                A description of the copyrighted work you claim has been infringed, including a copy
                of the copyrighted work or the web page address where the copyrighted work may be
                found;
              </li>
              <li>
                Identification of the location on St. Jude Cloud of the material you claim has been
                infringed, or the link or reference to another website that contains the material
                you claim has been infringed;
              </li>
              <li>Your name, address, telephone number and email address;</li>
              <li>
                A statement by you that you have a good faith belief that the disputed use of the
                material at issue is not authorized by the copyright owner, the agent of the
                copyright owner or the law; and
              </li>
              <li>
                A statement by you that the information in this notification is accurate and a
                statement, under penalty of perjury, that you are the copyright owner of the
                material allegedly infringed or authorized to act on the copyright owner&apos;s
                behalf.
              </li>
            </ul>
            <p>
              <i>Designated Agent Contact Information</i>. St. Jude&apos;s copyright agent for
              notice of claims of copyright infringement on St. Jude Cloud is ZwillGen PLLC.
              ZwillGen can be reached at 1900 M. Street, NW Washington, D.C. 20036,
              dmca@zwillgen.com, 202-296-3585, fax: 202-706-5298.
            </p>
            <p>
              <i>Counter Notification</i>. If you receive a notification from St. Jude that material
              made available by you on or through St. Jude Cloud has been the subject of a
              &apos;Notification of Claimed Infringement&apos;, then you will have the right to
              provide St. Jude a &apos;Counter Notification.&apos; A Counter Notification must be in
              writing, provided to our Designated Agent via a method identified above, and include
              substantially the following information:
            </p>
            <ul>
              <li>A physical or electronic signature of the User;</li>
              <li>
                Identification of the material that has been removed or to which access has been
                disabled and the location at which the material appeared before it was removed or
                access to it was disabled;
              </li>
              <li>
                A statement under penalty of perjury that the User has a good faith belief that the
                material was removed or disabled as a result of mistake or misidentification of the
                material to be removed or disabled; and
              </li>
              <li>
                The User&apos;s name, address, and telephone number, and a statement that the he/she
                consents to the jurisdiction of Federal District Court for the judicial district in
                which the address is located, or if outside the United States, for any judicial
                district in which St. Jude may be found, and that the User will accept service of
                process from the person who provided notification above or an agent of such person.
              </li>
            </ul>
            <p>
              A party submitting a notice of claimed infringement or a Counter Notification should
              consult a lawyer or see 17 U.S.C. &sect; 512 to confirm your obligations under the
              Copyright Act.
            </p>
            <p>
              False Notifications. St. Jude reserves the right to seek damages from any party that
              submits a notification of claimed infringement or counter notification in violation of
              the law.
            </p>
            <h4>No Warranties</h4>
            <p>
              ST. JUDE MAKES NO WARRANTY AS TO ST. JUDE CLOUD, THE ST. JUDE PROGRAMS, ST. JUDE APPS,
              OR THE ST. JUDE DATA. USE OF ST. JUDE CLOUD, THE ST. JUDE PROGRAMS, ST. JUDE APPS, OR
              THE ST. JUDE DATA IS AT USER&apos;S OWN RISK, AND USER AFFIRMATIVELY ACCEPTS, ON
              BEHALF OF USER AND ON BEHALF OF ANY INSTITUTION OR ORGANIZATION WITH WHICH USER IS
              AFFILIATED, THESE RISKS WITH EACH USE. ST. JUDE CLOUD, THE ST. JUDE PROGRAMS, ST. JUDE
              APPS, AND THE ST. JUDE DATA ARE PROVIDED &quot;AS IS,&quot; WITHOUT WARRANTY OF ANY
              KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT. ST.
              JUDE DOES NOT WARRANT THAT ST. JUDE CLOUD, THE ST. JUDE PROGRAMS, ST. JUDE APPS, OR
              THE ST. JUDE DATA, OR THEIR CONTENT OR OPERATION WILL BE SECURE, ACCURATE, RELIABLE,
              UNINTERRUPTED, OR ERROR-FREE. ST. JUDE MAKES NO WARRANTY THAT ST. JUDE CLOUD, THE ST.
              JUDE PROGRAMS, ST. JUDE APPS, OR THE ST. JUDE DATA ARE FREE OF VIRUSES, WORMS, TROJAN
              HORSES, TIME BOMBS, CANCELBOTS, OR OTHER DAMAGING, INTERFERING, OR HARMFUL SOFTWARE OR
              PROGRAMMING ROUTINES. ST. JUDE MAKES NO WARRANTY THAT THE CONTENT CONTAINED ON ST.
              JUDE CLOUD, THE ST. JUDE PROGRAMS, ST. JUDE APPS, OR THE ST. JUDE DATA SATISFIES
              GOVERNMENTAL REGULATIONS ON PRESCRIPTION DRUG PRODUCTS, CLEARANCE OR OTHER LICENSURE,
              APPROVAL, OR AUTHORIZATION FOR USE ON PATIENTS, OR ANY OTHER GOVERNMENTAL REGULATION.
              ST. JUDE HAS NO RESPONSIBILITY OR LIABILITY FOR ANY THIRD-PARTY CONTENT, APPLICATIONS,
              PROGRAMS, OR DATA ON ST. JUDE CLOUD, THE ST. JUDE PROGRAMS, ST. JUDE APPS, OR IN THE
              ST. JUDE DATA. YOU ASSUME ALL RISK FOR YOUR USE OF ST. JUDE CLOUD, THE ST. JUDE
              PROGRAMS, THE ST. JUDE APPS, OR THE ST. JUDE DATA AND FOR ANY UNLAWFUL INTERCEPTION,
              ACCESS, AND USE BY ANY UNAUTHORIZED THIRD PARTY. ST. JUDE MAKES NO WARRANTY REGARDING
              THE LAW OF YOUR JURISDICTION AND WHETHER IT ALLOWS ACCESS OR DOWNLOAD; YOU ASSUME THE
              RISK AND ARE SOLELY RESPONSIBLE FOR COMPLIANCE WITH THE LAWS OF YOUR JURISDICTION.
            </p>
            <p>
              No agent or representative of St. Jude has the authority to create any warranty
              regarding St. Jude Cloud, the St. Jude Programs, St. Jude Apps, or the St. Jude Data
              on behalf of St. Jude. St. Jude reserves the right to change or discontinue at any
              time any aspect or feature of St. Jude Cloud, the St. Jude Programs, the St. Jude
              Apps, or the St. Jude Data.
            </p>
            <h4>Exclusion of Liability</h4>
            <p>
              TO THE FULLEST EXTENT PERMITTED, ST. JUDE DISCLAIMS ALL WARRANTIES. UNDER NO
              CIRCUMSTANCES, INCLUDING WITHOUT LIMITATION NEGLIGENCE, WILL ST. JUDE OR ITS EMPLOYEES
              AND AGENTS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
              DAMAGES, LOSSES, OR EXPENSES (INCLUDING, WITHOUT LIMITATION, HEALTH PROBLEMS, LOST
              PROFITS, OR DAMAGES ARISING FROM LOST DATA OR BUSINESS INTERRUPTION) ARISING OUT OF OR
              IN ANY WAY CONNECTED WITH USE OF ST. JUDE CLOUD, THE ST. JUDE PROGRAMS, ST. JUDE APPS,
              OR THE ST. JUDE DATA, OR ANY FAILURE OR DELAY IN, OR ANY INABILITY TO USE, ST. JUDE
              CLOUD, THE ST. JUDE PROGRAMS, ST. JUDE APPS, OR THE ST. JUDE DATA, WHETHER SUCH
              DAMAGES ARE BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGES. USER SPECIFICALLY AGREES THAT NEITHER ST. JUDE NOR
              ANY LICENSOR, SUPPLIER, CONTRACTOR, SUBCONTRACTOR, OR INFORMATION PROVIDER TO ST. JUDE
              OR ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, OR ANY OF THEIR
              SUCCESSORS OR ASSIGNS, SHALL HAVE ANY LIABILITY TO USER OR ANY THIRD PARTIES FOR ANY
              REASON OR UNDER ANY THEORY WHATSOEVER, BASED UPON THE INFORMATION PROVIDED ON OR
              THROUGH ST. JUDE CLOUD OR USER&apos;S USE OF ST. JUDE CLOUD, THE ST. JUDE PROGRAMS,
              ST. JUDE APPS, OR THE ST. JUDE DATA. SHOULD THIS PROVISION BE UNENFORCEABLE, THE
              PARTIES AGREE THAT THE COLLCTIVE LIABILITY OF ST. JUDE AND ITS AFFILIATES, OFFICERS,
              DIRECTORS, EMPLOYEES, OR AGENTS, AND THEIR SUCCESSORS AND ASSIGNS, IS CAPPED AT THE
              AMOUNT USER HAS PAID ST. JUDE FOR THE USE OF ST. JUDE CLOUD, THE ST. JUDE PROGRAMS,
              ST. JUDE APPS, OR THE ST. JUDE DATA.
            </p>
            <p>
              INFORMATION OR SERVICES PROVIDED BY ST. JUDE ON ST. JUDE CLOUD, THE ST. JUDE PROGRAMS,
              ST. JUDE APPS, AND THE ST. JUDE DATA IS NEITHER MEDICAL ADVICE NOR A SUBSTITUTE FOR
              MEDICAL ADVICE AND IS NOT A SUBSTITUTE TO ANY PERSON&apos;S PRIMARY OBLIGATION TO
              MAINTAIN MEDICAL RECORDS AND TO COMPLY WITH ANY ORDERS, PRESCRIPTIONS, OR OTHER
              DIRECTIONS OF MEDICAL PROFESSIONALS. RELIANCE ON ST. JUDE CLOUD, THE ST. JUDE
              PROGRAMS, ST. JUDE APPS, OR THE ST. JUDE DATA IS AT THE USER&apos;S OWN RISK.
            </p>
            <h4>Indemnification</h4>
            <p>
              User agrees to indemnify St. Jude, the American Lebanese Syrian Associated Charities
              (ALSAC; a non-profit, 501(c)(3) corporation which supports St. Jude), and any of our
              parent or subsidiary companies or organizations, and any of our successors, assigns or
              licensees, together with any of their respective board members, officers, directors,
              and employees, against any damages, losses, liabilities, judgments, costs and expenses
              (including reasonable attorneys&apos; fees and costs) arising out of a claim by St.
              Jude or any third party relating to User&apos;s use (including without limitation
              User&apos;s uploading to or use of genomic information) of St. Jude Cloud, St. Jude
              Data, St. Jude Apps, and/or St. Jude Programs, and for any claim based on User&apos;s
              alleged or actual any breach or violation of these Terms or any other term or
              condition contained on St. Jude Cloud.
            </p>
            <h3>Arbitration and Class Action Waiver</h3>
            <p>
              <b>
                PLEASE READ THIS SECTION CAREFULLY. IT AFFECTS YOUR LEGAL RIGHTS, INCLUDING YOUR
                FILE A LAWSUIT IN COURT.
              </b>
            </p>
            <p>
              You and St. Jude agree that these Terms affect interstate commerce and that the
              Federal Arbitration Act governs the interpretation and enforcement of these
              arbitration provisions.
            </p>
            <p>
              This Section is intended to be interpreted broadly and governs any and all disputes
              between us arising under or related to any aspect of this Agreement or St. Jude Cloud,
              (including St, Jude Apps, St. Jude Programs, and/or St. Jude Data), whether based in
              contract, tort, statute, fraud, misrepresentation or any other legal theory; claims
              that arose before this Agreement or any prior agreement (including, but not limited
              to, claims related to advertising); and claims that may arise after the termination of
              these Terms. The only disputes excluded from this broad prohibition are the litigation
              of certain intellectual property and small court claims, as provided below.
            </p>
            <p>
              By agreeing to these Terms, you agree to resolve any and all disputes with St. Jude as
              follows:
            </p>
            <p>
              <i>Initial Dispute Resolution</i>: Most disputes can be resolved without resort to
              litigation. You can reach St. Jude support at{' '}
              <a
                href="https://hospital.stjude.org/apps/forms/fb/st-jude-cloud-contact/"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://stjude.cloud/contact
              </a>
              . Except for intellectual property and small claims court claims, the parties agree to
              use their best efforts to settle any dispute, claim, question, or disagreement
              directly through consultation with St. Jude, and good faith negotiations shall be a
              condition to either party initiating a lawsuit or arbitration.
            </p>
            <p>
              <i>Binding Arbitration</i>: If the parties do not reach an agreed-upon solution at
              least sixty (60) days from the time informal dispute resolution is initiated under the
              Initial Dispute Resolution provision above, then either party may initiate binding
              arbitration as the sole means to resolve claims, subject to the terms set forth below.
              Specifically, all claims arising out of or relating to these Terms (including the
              Terms&apos; or Privacy Policy&apos;s formation, performance, and breach), the
              parties&apos; relationship with each other, and/or your use of St. Jude Cloud shall be
              finally settled by binding arbitration administered by JAMS in accordance with the
              JAMS Streamlined Arbitration Procedure Rules for claims that do not exceed $250,000
              and the JAMS Comprehensive Arbitration Rules and Procedures for claims exceeding
              $250,000 in effect at the time the arbitration is initiated, excluding any rules or
              procedures governing or permitting class actions.
            </p>
            <p>
              The arbitrator, and not any federal, state, or local court or agency, shall have
              exclusive authority to resolve all disputes arising out of or relating to the
              interpretation, applicability, enforceability, or formation of these Terms or the
              Privacy Policy, including but not limited to any claim that all or any part of these
              Terms or Privacy Policy is void or voidable, whether a claim is subject to
              arbitration, or the question of waiver by litigation conduct. The arbitrator shall be
              empowered to grant whatever relief would be available in a court under law or in
              equity. The arbitrator&apos;s award shall be written and shall be binding on the
              parties and may be entered as a judgment in any court of competent jurisdiction. To
              start an arbitration, you must do the following: (a) write a Demand for Arbitration
              that includes a description of the claim and the amount of damages you seek to recover
              (you may find a copy of a Demand for Arbitration at www.jamsadr.com); (b) send three
              copies of the Demand for Arbitration, plus the appropriate filing fee, to the
              applicable JAMS Resolution Center, which can be found at
              http://www.jamsadr.com/locations; and (c) send one copy of the Demand for Arbitration
              to St. Jude at 282 Danny Thomas Place, MS 280, Memphis, TN 38105, ATTN: Office of
              Legal Services.
            </p>
            <p>
              Any arbitration demand or counterclaim asserted by either party must contain
              sufficient information to provide fair notice to the other party of the asserting
              party’s identity, the claims being asserted, and the factual allegations on which they
              are based. The arbitrator and/or JAMS may require amendment of any demand or
              counterclaim that does not satisfy these requirements. The arbitrator has the right to
              impose sanctions in accordance with JAMS Rule 24 for any claims the arbitrator
              determines to be frivolous or improper (under the standard set forth in Federal Rule
              of Civil Procedure 11).
            </p>
            <p>
              The parties agree that JAMS has discretion to modify the amount or timing of any
              administrative or arbitration fees due under JAMS&apos;s Rules where it deems
              appropriate, provided that such modification does not increase the costs to you, and
              you waive any objection to such fee modification. The parties also agree that a
              good-faith challenge by either party to the fees imposed by JAMS does not constitute a
              default, waiver, or breach of this Arbitration and Class Action Waiver while such
              challenge remains pending before JAMS, the arbitrator, and/or a court of competent
              jurisdiction.
            </p>
            <p>
              To the extent the filing fee for the arbitration exceeds the cost of filing a lawsuit,
              St. Jude will pay the additional cost. If the arbitrator finds the arbitration to be
              non-frivolous, St. Jude will pay the fees invoiced by JAMS, including filing fees and
              arbitrator and hearing expenses. You are responsible for your own attorneys&apos; fees
              unless the arbitration rules and/or applicable law provide otherwise.
            </p>
            <p>
              You and St. Jude understand that, absent this mandatory arbitration provision, each
              party would have the right to sue in court and have a jury trial. You and St. Jude
              further understand that, in some instances, the costs of arbitration could exceed the
              costs of litigation and the right to discovery may be more limited in arbitration than
              in court.
            </p>
            <p>
              If you are a resident of the United States, arbitration may take place in the county
              where you reside at the time of filing. For individuals residing outside the United
              States, arbitration shall be initiated in the State of Tennessee, United States of
              America, and you and St. Jude agree to submit to the personal jurisdiction of any
              federal or state court in Shelby County, Tennessee, in order to compel arbitration, to
              stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment
              on the award entered by the arbitrator.
            </p>
            <p>
              <i>Class Action Waiver</i>: The parties further agree that the arbitration shall be
              conducted by the parties in their individual capacities only and not as a class action
              or other representative action, and the parties expressly waive their right to file a
              class action or seek relief on a class basis. YOU AND ST. JUDE AGREE THAT EACH MAY
              BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A
              PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If
              there is a final judicial determination that applicable law precludes enforcement of
              this Section&apos;s limitations as to a particular remedy, then that remedy (and only
              that remedy) must be severed from the arbitration and may be sought in court. The
              parties agree, however, that any adjudication of remedies not subject to arbitration
              shall be stayed pending the outcome of any arbitrable claims and remedies.
            </p>
            <p>
              <i>Exception</i>: Litigation of Intellectual Property and Small Claims Court Claims:
              Notwithstanding the parties&apos; decision to resolve all disputes through
              arbitration, either party may bring enforcement actions, validity determinations, or
              claims arising from or relating to theft, piracy, or unauthorized use of intellectual
              property in state or federal court or in the U.S. Patent and Trademark Office to
              protect its intellectual property rights (&quot;intellectual property rights&quot;
              means patents, copyrights, moral rights, trademarks, and trade secrets, but not
              privacy or publicity rights). Either party may also seek relief in a small claims
              court for disputes or claims within the scope of that court’s jurisdiction. Either
              party may also seek a declaratory judgment or other equitable relief in a court of
              competent jurisdiction regarding whether a party&apos;s claims are time-barred or may
              be brought in small claims court in your state and county of residence. Seeking such
              relief shall not waive a party&apos;s right to arbitration under this agreement.
            </p>
            <p>
              <i>30-Day Right to Opt Out</i>: You have the right to opt out and not be bound by the
              arbitration and class action waiver provisions set forth above by sending notice of
              your decision to opt out to{' '}
              <a
                href="https://hospital.stjude.org/apps/forms/fb/st-jude-cloud-contact/"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://stjude.cloud/contact
              </a>{' '}
              with the subject line, &quot;ARBITRATION AND CLASS ACTION WAIVER OPT-OUT.&quot; The
              notice must be sent within thirty (30) days of your first use of St. Jude Cloud;
              otherwise, you shall be bound to arbitrate disputes in accordance with the terms of
              those paragraphs. If you opt out of these arbitration provisions, St. Jude also will
              not be bound by them.
            </p>
            <p>
              <i>Changes to This Section</i>: We will provide thirty (30) days&apos; notice of any
              changes to this section. Amendments will become effective thirty (30) days after such
              notice. Changes to this section will otherwise apply prospectively only to claims
              arising after the thirtieth (30th) day. If a court or arbitrator decides that this
              subsection on &quot;Changes to This Section&quot; is not enforceable or valid, then
              this subsection shall be severed from the section entitled &apos;Arbitration and Class
              Action Waiver,&apos; and the court or arbitrator shall apply the first Arbitration and
              Class Action Waiver section in existence after you began using St. Jude Cloud.
            </p>
            <p>
              <i>Survival</i>: This Arbitration and Class Action Waiver section shall survive any
              termination of your account or St. Jude Cloud.
            </p>
            <h4>Jurisdiction</h4>
            <p>
              User agrees that these Terms constitute an agreement entered into between User and St.
              Jude in the State of Tennessee, United States of America, and is performed within the
              State of Tennessee. User agrees that these Terms are governed by and will be construed
              in all respects under the laws of the State of Tennessee, exclusive of its choice of
              law or conflicts of law provisions. In any claim or action directly or indirectly
              arising under these Terms or related to St. Jude Cloud, User irrevocably agrees to
              submit to the exclusive personal jurisdiction of the state or federal courts located
              in or serving Shelby County, Tennessee. User waives any jurisdictional, venue, or
              inconvenient forum objections to such court.
            </p>
            <p>
              If any provision of these Terms is held to be not enforceable by a court or other
              tribunal of competent jurisdiction, then such provision will be limited or eliminated
              to the minimum extent necessary so that this agreement will otherwise remain in full
              force and effect.
            </p>
            <h4>Entire Agreement</h4>
            <p>
              These Terms, together with the St. Jude Cloud Privacy Policy (
              <a
                href="https://stjude.cloud/privacy-policy"
                rel="noopener noreferrer"
                target="_blank"
              >
                https://stjude.cloud/privacy-policy
              </a>
              ), and other terms required to access St. Jude Cloud, St. Jude Data, St. Jude Apps,
              and St. Jude Programs constitute the entire agreement between User and St. Jude
              relating to the access and use of St. Jude Cloud, St. Jude Data, St. Jude Apps, and
              St. Jude Programs. User may not modify these Terms. Anything contained in St. Jude
              Cloud, St. Jude Apps, St. Jude Data, and St. Jude Programs that is inconsistent with
              or conflicting with the terms of this agreement is superseded by these Terms.
              Notwithstanding the foregoing, if User and St. Jude&apos;s authorized representative
              execute an Agreement for Access To and/or Download of Controlled-Access Genomic
              Sequencing Data, they become a part of the entire agreement between User and St. Jude.
            </p>
          </div>
        </section>
      </div>
    </Container>
  </Layout>
);

export default TermsOfUse;
